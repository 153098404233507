<template>
	<div>
		<br />
		<br />
		<br />
		<br />
		<form id="loginForm" action="" method="POST" @submit.prevent="login" ref="loginForm">
			<div class="panel panel-body login-form">
				<div class="text-center">
					<br />
					<br />
					<div>
						<img src="/images/aikl-logo.jpg" width="200px" class="ico appico-img-rounded" />
					</div>
					<br />
					<h5 class="content-group">
						Aikl 건설안전시스템
						<small class="display-block">로그인 계정을 입력해주세요.</small>
					</h5>
				</div>

				<div class="mobile_auth hidden" style="display: flex; align-items: center; justify-content: center; flex-direction: column">
					<span style="font-size: 1.4em; margin-bottom: 10px">자동 인증 중...</span>
					<i class="fa fa-spinner fa-spin fa-3x"></i>
				</div>

				<div class="form-group has-feedback has-feedback-left">
					<input
						type="text"
						name="username"
						class="form-control"
						placeholder="사용자ID"
						v-model="username"
						ref="username"
						v-validate="'required|userIdCheck'"
						data-vv-as="사용자ID"
						maxlength="20"
					/>
					<div class="form-control-feedback">
						<i class="icon-user text-muted"></i>
					</div>
					<span class="validation-error-label">{{ errors.first('username') }}</span>
				</div>

				<div class="form-group has-feedback has-feedback-left">
					<input
						type="password"
						name="password"
						class="form-control"
						placeholder="비밀번호"
						v-model="password"
						ref="password"
						v-validate="'required'"
						data-vv-as="비밀번호"
						maxlength="16"
					/>
					<div class="form-control-feedback">
						<i class="icon-lock2 text-muted"></i>
					</div>
					<span class="validation-error-label">{{ errors.first('password') }}</span>
				</div>

				<div class="form-group has-feedback has-feedback-left" style="margin-bottom: 0px; margin-top: -10px">
					&nbsp;
					<label for="idsave">
						<input type="checkbox" v-model="idsave" id="idsave" name="idsave" style="transform: scale(1)" />
						아이디 저장
					</label>
				</div>
				<div class="form-group">
					<button type="submit" class="btn bg-blue btn-block">
						로그인
						<i class="icon-arrow-right14 position-right"></i>
					</button>
					<br />
				</div>
			</div>

			<div class="modal fade" ref="modal" sites>
				<div class="modal-dialog modal-sm">
					<div class="modal-content">
						<div class="modal-header">
							<!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
							<h5 class="modal-title">현장 선택</h5>
						</div>
						<div class="modal-body">
							<select class="form-control" v-model="selectSite" style="font-size: 16px">
								<option value>선택</option>
								<option v-for="(option, index) in selectOption" :value="index" :key="index">
									{{ option.siteNm }}
								</option>
							</select>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary pull-right" @click="selectBtn" selectBtn>확인</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import VueCookies from 'vue-cookies';
import apiIndex from '../../api/index';
import _ from 'lodash';
const loginUrl = apiIndex.login;

export default {
	computed: {
		...mapState({
			userInfo: state => state.userInfo,
		}),
	},

	data: () => ({
		username: VueCookies.get('idsave'), // 아이디
		password: '', // 비밀번호
		idsave: VueCookies.get('idsave') ? VueCookies.get('idsave') : '',
		errorMsg: '',
		selectSite: '', // 현재 선택한 현장의 key value
		selectOption: [], // 셀렉트 박스 값(로그인할 유저의 현장들)
		validateMsg: {
			username: [],
			password: [],
		},
	}),

	mounted() {
		if (this.errorMsg) {
			console.log(this.errorMsg);
		}

		$(document).ready(function () {
			$('body').addClass('login-container');
			$('body').addClass('login-bg');
		});

		localStorage.clear();

		// 이미 로그인 된 사용자가 있는지 확인
		this.$axios
			.post(loginUrl.loginUserChk, { menuGubun: process.env.VUE_APP_MENU_GUBUN })
			.then()
			.catch(error => {
				console.log(error);
			});
	},

	methods: {
		login() {
			this.siteId = '';

			// 자동인증에서만 처리
			if ($('#loginForm .content-group').hasClass('hidden')) {
				this.username = $('#loginForm').data('name');
				this.password = $('#loginForm').data('pwd');
			}

			// 아이디 입력 폼 validation
			if (_.isEmpty(this.username)) {
				alert('사용자ID를 입력해주세요');
				return this.$refs.username.focus();
			}

			// 비밀번호 입력 폼 validation
			if (_.isEmpty(this.password)) {
				alert('비밀번호를 입력해주세요');
				return this.$refs.password.focus();
			}

			// selectbox에 값에 필요한 현재 계정의 현장 정보를 조회
			this.$axios
				.post(loginUrl.inqUserSite, { userId: this.username, passwd: this.password, menuGubun: process.env.VUE_APP_MENU_GUBUN })
				.then(
					function (r) {
						// 입력한 아이디와 비밀번호가 일치하는 회원이면 response 받은 현장 정보를 저장
						if (r.data) {
							// 셀렉트 박스 내용을 현재 로그인할 유저의 현장들로 세팅
							this.selectOption = r.data;

							// 로그인 할 계정의 현장이 2개 이상이면 모달을 호출
							if (r.data.length > 1) {
								$('.modal[sites]').modal({
									backdrop: 'static',
									show: true,
									keyboard: true,
								});

								// if (typeof webkit !== 'undefined' && webkit.messageHandlers && webkit.messageHandlers.callbackHandler)
								// 	webkit.messageHandlers.callbackHandler.postMessage({
								// 		act: 'zoom/out',
								// 	});
							}
							// 현장이 1개면 바로 로그인 처리
							else {
								this.selectSite = 0;
								this.selectBtn();
							}
						}
						// 현장이 0개일때
						else {
							this.selectSite = 0;
							this.selectBtn();
						}
					}.bind(this),
				)
				.catch(error => {
					alert(error.response.data.message);
				});
		},
		// 현장 선택 버튼 이벤트
		selectBtn() {
			// 로그인 유저의 현장이 0개일때
			if (!this.selectOption.length) return alert('소속된 현장이 없습니다.\n관리자에게 문의해주시기 바랍니다.');

			// 선택된 현장 값 세팅 후 form으로 넘기기
			if (this.selectSite === '') return alert('현장을 선택해 주십시오');

			let selectedSiteId = '';

			// 현장을 선택하면 여러 번 요청을 방지하기 위해 셀렉트 박스와 버튼을 disabled 처리
			$('[selectBtn]')
				.prop('disabled', true)
				.prepend("<i class='fa fa-spin fa-spinner'></i> ")
				.closest('.modal')
				.find('select')
				.prop('disabled', true);

			// hidden 타입으로 삽입된 현장 정보를 clear(로그인 실패할 경우 계속 쌓이는 걸 방지)
			$('#loginForm').find('input[type=hidden]').remove();

			// 현재 선택된 현장의 정보를 hidden 타입으로 삽입해 전달
			$.each(this.selectOption[this.selectSite ? this.selectSite : 0], function (k, v) {
				$('<input type=hidden>').attr({ name: k, value: v }).appendTo('#loginForm');
				if (k == 'siteId') selectedSiteId = v;
			});

			let that = this;

			// selectbox에서 선택한 siteId 값을 jwt에 저장하기 위해 controller 한번 더 호출
			this.$axios
				.post(loginUrl.setUserSiteId, { siteId: selectedSiteId })
				.then(function () {
					// 아이디 저장 여부 확인 후 쿠키에 아이디를 저장
					VueCookies.set('idsave', that.idsave ? that.username : '', '365d'); // 365 day

					// submit 처리
					that.$refs.loginForm.action = loginUrl.loginProcess;
					that.$refs.loginForm.submit();
				})
				.catch(error => {
					alert(error.response.data.message);
				});
		},
	},
};
</script>

<style scoped>
/* .form-control {
	width: 90%;
	margin: auto;
}
.form-control-feedback {
	margin-left: 15px;
} */
.validation-error-label {
	margin-left: 15px;
}
</style>
